import React, { useEffect } from 'react'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

export enum DialogLevel {
  Underlying = -1,
  Default = 1,
  Top = 1000,
}

export type DialogProps = {
    onClose: (level: DialogLevel) => void
    backdrop?: React.ReactElement
    level: number
    hideCloseIcon?: boolean
}


const Dialog: React.FunctionComponent<DialogProps> = ({ onClose, children, backdrop, level, hideCloseIcon }) => {
    useEffect(() => {
        const bind = (e: KeyboardEvent) => {
          if (e.key !== "Escape") {
            return
          }
    
          if (document.activeElement && ['INPUT', 'SELECT'].includes(document.activeElement.tagName)) {
            return
          }
    
          onClose(level)
        }
    
        document.addEventListener('keyup', bind)
        return () => document.removeEventListener('keyup', bind)
      }, [onClose, children])

    return (
        <>
        <BackdropContainer onClick={() => onClose(level)}>
            { backdrop || <Backdrop /> }
            <Container onClick={ev => ev.stopPropagation()}>
                { !hideCloseIcon && <Closer onClick={() => onClose(level)} src={require("./images/close.svg")}/> }
                { children }
            </Container>
        </BackdropContainer>
        </>
    )
}


const BackdropContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  
  bottom: 0;
  right: 0;

  display: grid;
  justify-items: center;
  align-items: center;

  z-index: 100;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(33, 33, 47, 0.4);
`

const Container = styled.div`
  position: relative;
  
  display: grid;
  align-self: center;

  width: 100%;
  height: 100%;
  max-height: 100%;

  overflow-y: auto;

  ${up('tablet')} {
    margin: 0 32px;
    height: auto;
    width: auto;
  }
`

const Closer = styled.img`
  position: absolute;

  top: 22px;
  right: 22px;

  ${up('tablet')} {
    top: 40px;
    right: 40px;
  }

  width: 16px;
  height: 16px;

  z-index: 21;
  cursor: pointer;
`

export default Dialog